<template>
  <div class="container">
    <div class="card pb-0" style="position: relative">
      <el-form :inline="true" class="screen-form">
        <el-form-item label="名称" class="pb-10">
          <el-input
            clearable
            v-model="form.name"
            placeholder="支持模糊搜索"
            style="width: 220px"
          ></el-input>
        </el-form-item>
        <el-form-item label="移植类型" class="pb-10">
          <el-select
            clearable
            v-model="form.type"
            fit-input-width
            placeholder="查看全部"
            style="width: 140px"
          >
            <el-option
              v-for="item in transplant"
              :key="'transplant_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="pb-10">
          <el-button type="primary" :loading="loading" @click="getNumber">
            <el-icon v-if="!loading"><search /></el-icon>
            <span>筛选</span>
          </el-button>
          <el-button @click="clean">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button class="add-btn" type="primary" @click="openAdd">
        <el-icon><plus /></el-icon>
        <span>添加</span>
      </el-button>
    </div>
    <div class="card table-card no-select" v-loading="loading">
      <el-table
        :data="list"
        style="width: 100%; border-radius: 8px"
        :row-style="{ cursor: 'pointer' }"
        @row-click="openInfo"
        v-if="list.length > 0"
      >
        <el-table-column prop="id" label="编号" width="80" />
        <el-table-column prop="name" label="名称" />
        <el-table-column prop="transplantName" label="移植类型" width="120" />
        <el-table-column label="起始时间点" align="center" width="120">
          <template #default="scope">
            <span v-if="scope.row.based === 1">手术日期</span>
            <span v-else-if="scope.row.based === 2">出院日期</span>
          </template>
        </el-table-column>
        <el-table-column prop="lastTime" label="上次更新时间" width="200" />
        <el-table-column label="操作" align="center" width="180">
          <template #default="scope">
            <el-button size="small" plain @click.stop="openEdit(scope.row)"
              >编辑基础信息</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click.stop="remove(scope.row.id, scope.row.name)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-empty class="loading" v-else description="暂无符合的模板">
        <el-button @click="clean">重置筛选条件</el-button>
      </el-empty>
    </div>
    <div class="card mb-0" v-if="!loading && total > 0">
      <div class="page-number">每页{{ number }}条 / 共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="number"
        @current-change="getList"
      />
    </div>
    <el-dialog
      custom-class="edit-dialog"
      v-model="dialog.show"
      :title="dialog.model === 'add' ? '添加模板' : '编辑模板基础信息'"
      width="350px"
    >
      <el-form>
        <el-form-item required label="模板名称">
          <el-input
            v-model="dialog.name"
            autocomplete="off"
            style="width: 230px"
            :disabled="dialog.loading"
          ></el-input>
        </el-form-item>
        <el-form-item required label="移植类型">
          <el-select
            clearable
            v-model="dialog.type"
            fit-input-width
            placeholder="请选择移植类型"
            style="width: 160px"
            :disabled="dialog.loading"
          >
            <el-option
              v-for="item in transplant"
              :key="'transplant_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label="时间起点" class="mb-0">
          <el-select
            clearable
            v-model="dialog.based"
            fit-input-width
            placeholder="请选择时间起点"
            style="width: 160px"
            :disabled="dialog.loading"
          >
            <el-option label="手术日期" :value="1"></el-option>
            <el-option label="出院日期" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog.show = false" :disabled="dialog.loading"
            >取消</el-button
          >
          <el-button
            type="primary"
            @click="add"
            :loading="dialog.loading"
            v-if="dialog.model === 'add'"
            >添加</el-button
          >
          <el-button
            type="primary"
            @click="update"
            :loading="dialog.loading"
            v-else
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Search, Plus } from "@element-plus/icons";
import { common, follow } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "TemplateList",
  components: { Search, Plus },
  data: () => ({
    loading: true,
    form: {
      name: "",
      type: "",
    },
    dialog: {
      loading: false,
      model: "add",
      show: false,
      based: 0,
      name: "",
      type: 0,
      id: 0,
    },
    list: [],
    transplant: [],
    number: 13,
    total: 0,
    page: 0,
  }),
  methods: {
    init() {
      let height = document.body.clientHeight;
      if (height > 768) this.number = 14 + parseInt((height - 768) / 40);
      this.initCache();
      this.getNumber();
    },
    initCache() {
      let cache = localStorage.getItem("cache_transplant");
      if (cache) this.transplant = JSON.parse(cache);
      common.initPage("/template/list").then((res) => {
        if (res.state) {
          localStorage.setItem("cache_transplant", JSON.stringify(res.data));
          this.transplant = res.data;
        }
      });
    },
    getNumber() {
      this.loading = true;
      follow
        .getTemplateNumber(this.form)
        .then((res) => {
          this.total = res.data;
          if (this.total > 0) this.getList(1);
          else {
            this.list = [];
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.total = 0;
        });
    },
    getList(page) {
      this.page = page;
      follow
        .getTemplateList(this.form, page, this.number)
        .then((res) => {
          for (let i in res.data) {
            let lastTime = res.data[i].lastTime;
            if (lastTime)
              res.data[i].lastTime = date.format(
                lastTime,
                "yyyy-mm-dd HH:MM:SS"
              );
          }
          setTimeout(() => {
            this.list = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clean() {
      this.form = {
        name: "",
        type: "",
      };
      this.getNumber();
    },
    openInfo(row) {
      this.$router.push("/template/" + row.id);
    },
    openAdd() {
      this.dialog = {
        loading: false,
        model: "add",
        show: true,
        based: "",
        name: "",
        type: "",
        id: "",
      };
    },
    openEdit(item) {
      this.dialog = {
        loading: false,
        model: "edit",
        show: true,
        based: item.based,
        name: item.name,
        type: item.transplant,
        id: item.id,
      };
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    add() {
      if (this.dialog.based === "") return this.showWarning("时间起点不能为空");
      if (this.dialog.name === "") return this.showWarning("模板名称不能为空");
      if (this.dialog.type === "") return this.showWarning("移植类型不能为空");
      this.dialog.loading = true;
      follow
        .addTemplate(this.dialog)
        .then((res) => {
          setTimeout(() => {
            this.dialog.loading = false;
            if (res.state) {
              this.dialog.show = false;
              this.getNumber();
              this.$message.success({
                message: "添加成功",
                center: true,
              });
            } else {
              this.showWarning("添加失败,发生意料之外的错误");
            }
          }, 500);
        })
        .catch(() => {
          this.dialog.loading = false;
          this.$message.error("网络错误");
        });
    },
    update() {
      this.dialog.loading = true;
      follow
        .updateTemplateBasics(this.dialog)
        .then((res) => {
          setTimeout(() => {
            this.dialog.loading = false;
            if (res.state) {
              this.dialog.show = false;
              this.getList(this.page);
              this.$message.success({
                message: "提交成功",
                center: true,
              });
            } else {
              this.showWarning("提交失败,发生意料之外的错误");
            }
          }, 500);
        })
        .catch(() => {
          this.dialog.loading = false;
          this.$message.error("网络错误");
        });
    },
    remove(id, name) {
      this.$confirm("确认要删除“" + name + "”吗", "删除模板", {
        confirmButtonText: "确认删除",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        follow
          .removeTemplate(id)
          .then((res) => {
            setTimeout(() => {
              if (res.state) {
                this.getNumber();
                this.$message.success({
                  message: "删除成功",
                  center: true,
                });
              } else {
                this.showWarning("删除失败,发生意料之外的错误");
              }
            }, 300);
          })
          .catch(() => {
            this.$message.error("网络错误");
          });
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.screen-form .el-form-item {
  margin: 0 10px 0 0;
}

.page-number {
  align-items: center;
  font-size: 14px;
  display: flex;
  color: #999;
  height: 35px;
  float: right;
}

.table-card {
  min-height: calc(100vh - 200px);
}

.loading {
  margin-top: calc(50vh - 300px);
}

.add-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
<style>
.edit-dialog .el-dialog__body {
  padding: 10px 20px;
}
</style>